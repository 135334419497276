<div class="footer-div">
    <footer class="container">
        <div class="row footer-row pt-5 d-flex justify-content-around">
            <div class="col-md-6 col-sm-12">
                <h3 class="theme-heading">GO2 Solutions</h3>
                <p class="theme-p">
                    GO2 Solutions (GO2) offers a diverse range of Managed Services, which helps organizations outsource
                    ICT
                    functions and focus on the core deliverables to improve organizational profitability.
                </p>
            </div>
            <div class="col-md-4 col-sm-5">
                <h3 class="theme-heading">Contact</h3>
                <span class="d-flex">
                    <span class="pe-2"><i class="bi bi-geo-alt-fill"></i></span>
                    <p class="text-justify theme-p">
                        Level 5 Fiji Development Bank Center,<br /> 360 Victoria
                        Parade, Suva, Fiji
                    </p>
                </span>
                <span class="d-flex">
                    <span class="pe-2"><i class="bi bi-telephone-fill"></i></span>
                    <p class="theme-p">
                        (+679) 999 7023
                    </p>
                </span>               
            </div>
            <div class="col-md-2 col-sm-5 ">
                <h3 class="theme-heading">Menu</h3>
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link footer-nav-link theme-li" [routerLink]="['/']">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link footer-nav-link theme-li" [routerLink]="['/about']">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link footer-nav-link theme-li" [routerLink]="['/services']">Services</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link footer-nav-link theme-li" [routerLink]="['/portfolio']">Portfolio</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link footer-nav-link theme-li" [routerLink]="['/careers']">Careers</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link footer-nav-link theme-li" [routerLink]="['/contact-us']">Contact Us</a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col text-center">
                <h3 class="theme-heading">Follow Us</h3>
                <div>
                    <a class="px-2 social-icon text" href="https://twitter.com/go2sol" aria-label="twitter"><i
                            class="bi bi-twitter"></i></a>
                    <a class="px-2 social-icon" href="https://www.facebook.com/GO2SOL" aria-label="facebook"><i
                            class="bi bi-facebook"></i></a>
                    <a class="px-2 social-icon" href="https://instagram.com/go2_solutions" aria-label="instagram"><i
                            class="bi bi-instagram"></i></a>
                    <a class="px-2 social-icon" href="https://www.linkedin.com/company/go2sol" aria-label="linkedin"><i
                            class="bi bi-linkedin"></i></a>
                </div>
                <p class="mt-5 theme-p">
                    <strong class="pe-2">Copyright &copy; {{ getCurrentYear() }} GO2 Solutions. </strong> All rights
                    reserved.
                </p>
            </div>
        </div>
    </footer>
</div>