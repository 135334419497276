import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebsiteLayoutComponent } from './layout/website-layout/website-layout.component';
import { HomeComponent } from './pages/website-pages/pages/home/home.component';
import { LoggedInGuard } from 'src/api-authorization/loggedIn.guard';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';

export const routes: Routes = [
  { path: '', component: WebsiteLayoutComponent, children: [
      { path: '', component: HomeComponent,  data: { title: 'Home' }  },
      { path: 'about', loadChildren: () => import('./pages/website-pages/pages/about/about.module').then(m=>m.AboutModule),  data: { title: 'About' }  },
      { path: 'careers', loadChildren: () => import('./pages/website-pages/pages/careers/careers.module').then(m=>m.CareersModule),  data: { title: 'Careers' }  },
      { path: 'careers/:id', loadChildren: () => import('./pages/website-pages/pages/career-by-id/career-by-id.module').then(m=>m.CareerByIdModule),  data: { title: 'Careers' }  },
      { path: 'services', loadChildren: () => import('./pages/website-pages/pages/services/services.module').then(m=>m.ServicesModule),  data: { title: 'Services' }  },
      { path: 'contact-us', loadChildren: () => import('./pages/website-pages/pages/contact-us/contact-us.module').then(m=>m.ContactUsModule),  data: { title: 'Contact Us' }  },
      { path: 'portfolio', loadChildren: () => import('./pages/website-pages/pages/portfolio-index/portfolio-index.module').then(m=>m.PortfolioIndexModule),  data: { title: 'Portfolio' }  },
      { path: 'portfolio/:id', loadChildren: () => import('./pages/website-pages/pages/portfolio-by-id/portfolio-by-id.module').then(m=>m.PortfolioByIdModule),  data: { title: 'Portfolio' }  },
      { path: 'promotions', loadChildren: () => import('./pages/website-pages/pages/promotion-index/promotion-index.module').then(m=>m.PromotionIndexModule),  data: { title: 'Promotions' }  },
      { path: 'promotions/:id', loadChildren: () => import('./pages/website-pages/pages/promotion-by-id/promotion-by-id.module').then(m=>m.PromotionByIdModule),  data: { title: 'Promotions' }  },
    ], 
  },
  { path: 'login', loadChildren: () => import('../api-authorization/login/login.module').then(m=>m.LoginModule),  canActivate: [LoggedInGuard] },
  { path: 'admin', loadChildren: () => import('./layout/admin-layout/admin-layout.module').then(m=>m.AdminLayoutModule),canActivate: [AuthorizeGuard] },
  { path: 'forbidden', loadChildren: () => import('./pages/admin-portal/forbidden/forbidden.module').then(m=>m.ForbiddenModule) },
  { path: 'reset-password', loadChildren: () => import('./pages/admin-portal/reset-password/reset-password.module').then(m=>m.ResetPasswordModule)},
  { path: 'reset-password/:id', loadChildren: () => import('./pages/admin-portal/reset-password/reset-password.module').then(m=>m.ResetPasswordModule)},
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
