import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizeService } from "./authorize.service";

@Injectable({
    providedIn: 'root'
  })
  export class LoggedInGuard  {
    constructor(private authService: AuthorizeService, private router: Router) {}
  
    canActivate(): boolean {
      if (this.authService.isAuthenticated()) {
        this.router.navigate(['/admin/dashboard']);
        return false;
      } else {
        return true;
      }
    }
  }