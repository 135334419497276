import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';

import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IntersectionObserverDirective } from './pages/website-pages/directives/intersection-observer-directive.directive';
import { AboutComponent } from './pages/website-pages/pages/about/about.component';
import { CareersComponent } from './pages/website-pages/pages/careers/careers.component';
import { ContactUsComponent } from './pages/website-pages/pages/contact-us/contact-us.component';
import { ServicesComponent } from './pages/website-pages/pages/services/services.component';
import { FooterComponent } from './pages/website-pages/shared/footer/footer.component';
import { HeaderComponent } from './pages/website-pages/shared/header/header.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { WebsiteLayoutComponent } from './layout/website-layout/website-layout.component';
import { DashboardComponent } from './pages/admin-portal/dashboard/dashboard.component';
import { HomeComponent } from './pages/website-pages/pages/home/home.component';
import { SidebarComponent } from './pages/admin-portal/sidebar/sidebar.component';
import { DeleteBtnCellRendererComponent } from './components/delete-btn-cell-renderer/delete-btn-cell-renderer.component';
import { EditBtnCellRendererComponent } from './components/edit-btn-cell-renderer/edit-btn-cell-renderer.component';
import { GridComponent } from './components/grid/grid.component';
import { DateRendererComponent } from './components/date-renderer/date-renderer.component';
import { AgGridModule } from 'ag-grid-angular';
import { ServicesManagementComponent } from './pages/admin-portal/services-management/services-management.component';
import { VacancyComponent } from './pages/admin-portal/vacancy/vacancy/vacancy.component';
import { CreateVacancyComponent } from './pages/admin-portal/vacancy/create-vacancy/create-vacancy.component';
import { EditVacancyComponent } from './pages/admin-portal/vacancy/edit-vacancy/edit-vacancy.component';
import { CreatePortfolioComponent } from './pages/admin-portal/portfolio/create-portfolio/create-portfolio.component';
import { EditPortfolioComponent } from './pages/admin-portal/portfolio/edit-portfolio/edit-portfolio.component';
import { PortfolioComponent } from './pages/admin-portal/portfolio/portfolio/portfolio.component';
import { ClientComponent } from './pages/admin-portal/client/client.component';
import { PortfolioIndexComponent } from './pages/website-pages/pages/portfolio-index/portfolio-index.component';
import { PortfolioByIdComponent } from './pages/website-pages/pages/portfolio-by-id/portfolio-by-id.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CareerByIdComponent } from './pages/website-pages/pages/career-by-id/career-by-id.component';
import { UsersComponent } from './pages/admin-portal/users/users.component';
import { CreateUserComponent } from './pages/admin-portal/create-user/create-user.component';
import { EditUserComponent } from './pages/admin-portal/edit-user/edit-user.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ConfirmEqualValidatorDirectiveDirective } from './directives/confirm-equal-validator-directive.directive';
import { LoginComponent } from 'src/api-authorization/login/login.component';
import { ForbiddenComponent } from './pages/admin-portal/forbidden/forbidden.component';
import { PromotionComponent } from './pages/admin-portal/promotion/promotion/promotion.component';
import { CreatePromotionComponent } from './pages/admin-portal/promotion/create-promotion/create-promotion.component';
import { EditPromotionComponent } from './pages/admin-portal/promotion/edit-promotion/edit-promotion.component';
import { FileDropZoneComponent } from './components/file-drop-zone/file-drop-zone.component';
import { PromotionIndexComponent } from './pages/website-pages/pages/promotion-index/promotion-index.component';
import { PromotionByIdComponent } from './pages/website-pages/pages/promotion-by-id/promotion-by-id.component';
import { MultipleFileDropZoneComponent } from './components/multiple-file-drop-zone/multiple-file-drop-zone.component';
import { NgOptimizedImage } from '@angular/common';
import { ScrollToTopButtonComponent } from './components/scroll-to-top-button/scroll-to-top-button.component';
import { ViewBtnCellRendererComponent } from './components/view-btn-cell-renderer/view-btn-cell-renderer.component';
import { VacancyApplicationComponent } from './pages/admin-portal/vacancy/vacancy-application/vacancy-application.component';
import { ViewVacancyApplicationComponent } from './pages/admin-portal/vacancy/view-vacancy-application/view-vacancy-application.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { AlphanumericSpaceKeydownDirectiveDirective } from './directives/alphanumeric-space-keydown-directive.directive';
import { ResetPasswordComponent } from './pages/admin-portal/reset-password/reset-password.component';
import { ResetPasswordAuthenticatedComponent } from './pages/admin-portal/reset-password-authenticated/reset-password-authenticated.component';

@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        ContactUsComponent,
        ServicesComponent,
        AboutComponent,
        CareersComponent,
        IntersectionObserverDirective,
        AdminLayoutComponent,
        WebsiteLayoutComponent,
        DashboardComponent,
        SidebarComponent,
        GridComponent,
        DeleteBtnCellRendererComponent,
        EditBtnCellRendererComponent,
        DateRendererComponent,
        PortfolioComponent,
        ServicesManagementComponent,
        VacancyComponent,
        CreateVacancyComponent,
        EditVacancyComponent,
        CreatePortfolioComponent,
        EditPortfolioComponent,
        ClientComponent,
        PortfolioIndexComponent,
        PortfolioByIdComponent,
        CareerByIdComponent,
        UsersComponent,
        CreateUserComponent,
        EditUserComponent,
        ConfirmEqualValidatorDirectiveDirective,
        LoginComponent,
        ForbiddenComponent,
        PromotionComponent,
        CreatePromotionComponent,
        EditPromotionComponent,
        FileDropZoneComponent,
        PromotionIndexComponent,
        PromotionByIdComponent,
        MultipleFileDropZoneComponent,
        ScrollToTopButtonComponent,
        ViewBtnCellRendererComponent,
        VacancyApplicationComponent,
        ViewVacancyApplicationComponent,
        ConfirmationModalComponent,
        AlphanumericSpaceKeydownDirectiveDirective,
        ResetPasswordComponent,
        ResetPasswordAuthenticatedComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        AgGridModule,
        AngularEditorModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgOptimizedImage], 
        providers: [
          { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
          provideHttpClient(withInterceptorsFromDi()),
          {provide: 'nonce', useValue: '883423f8d21d81fe82df4843090e40a2'}
        ] })
export class AppModule { }
