import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PromotionClient } from 'src/app/web-api-client';

@Component({
  selector: 'app-website-layout',
  templateUrl: './website-layout.component.html',
  styleUrls: ['./website-layout.component.css']
})
export class WebsiteLayoutComponent implements OnInit {
  isLoading: boolean = false;
  promotionsCount: number;
  showPromotions: boolean;

  constructor(private promotionsClient: PromotionClient,) { 

  }

  ngOnInit() {
    let self = this;
    self.isLoading = true;
    self.promotionsClient.getPromotionsCountForWebsite().subscribe({
      next: function (response) {
        self.promotionsCount= response.data;
        if(self.promotionsCount > 0){
          self.showPromotions = true
        }
        else{
          self.showPromotions = false
        }
      }.bind(self),
      error(error: HttpErrorResponse) {
        self.isLoading = false;
      },
      complete: () => {
        self.isLoading = false;
      },
    });
  }

}