<div class="home">
    <div id="carousel1" class=" carousel slide carousal-border" data-bs-ride="carousel">
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carousel1" data-bs-slide-to="0" class="active" aria-current="true"
                aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carousel1" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carousel1" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="carousel-image-wrapper" fetchpriority="low">
                    <img fetchpriority="auto" loading="eager" [ngSrc]="carousal1" alt="Carousel Image 1" class="carousel-image" width="1920" height="1080">
                    <div class="carousel-caption">
                        <h2 class="heading theme-heading">Hello.<br />
                            We are your GO2 Solutions.</h2>
                        <a class="text-white theme-a" [routerLink]="['/about']">JUST WHO ARE WE?</a>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="carousel-image-wrapper" fetchpriority="low">
                    <img [ngSrc]="carousal2" alt="Carousel Image 2" class="carousel-image" width="1920" height="1080">
                    <div class="carousel-caption">
                        <h2 class="heading theme-heading">IT Solutions and Managed Services</h2>
                        <a class="text-white theme-p" [routerLink]="['/services']">WHAT DO WE DO?</a>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="carousel-image-wrapper" fetchpriority="low">
                    <img [ngSrc]="carousal3" alt="Carousel Image 2" class="carousel-image" width="1920" height="1080">
                    <div class="carousel-caption">
                        <h2 class="heading theme-heading">A Team Full of Surprises <br />
                            and Growth</h2>
                        <a class="text-white theme-a" [routerLink]="['/careers']">FEEL LIKE JOINING?</a>
                    </div>
                </div>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carousel1" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carousel1" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
    <div class="service-div ">
        <div class="container mb-5">
            <div class="row">
                <div appIntersectionObserver
                    class="hidden-from-left service1-child mb-2  serviceColLeft col-md-6 col-sm-12">
                    <div class="col serviceLeft pe-3">
                        <h1 class="serviceNumber theme-heading">01</h1>
                        <h2 class="theme-heading">WE SHAPE IT THROUGH DIGITAL TRANSFORMATION, LEVERAGING INNOVATIVE TECHNOLOGIES TO DRIVE POSITIVE CHANGES AND ENHANCE CAPABILITIES.</h2>
                    </div>

                    <button class="theme-button home-button mb-2" [routerLink]="['/about']"><span>About
                            Us</span></button>
                </div>
                <div appIntersectionObserver class="hidden-from-right service1-child col-md-3 col-sm-12">
                    <h3 class="theme-heading">Years Of Expertise</h3>
                    <p class="theme-p">
                        Within the dynamic landscape of the Managed Services industry, 
                        our expert Engineering team, boasting decades of experience, excels 
                        in integrating digital technology with cutting-edge AI solutions. 
                        We navigate the ever-evolving industry landscape through streamlined 
                        business processes to deliver market-driven solutions.
                    </p>
                </div>
                <div appIntersectionObserver class="hidden-from-right service1-child col-md-3 col-sm-12">
                    <h3 class="theme-heading">Branching Out</h3>
                    <p class="theme-p">
                        Whether it's Consulting, Product Management, or Service Delivery, 
                        we have honed the skill of customizing our offerings to meet the 
                        unique needs of our customers. Equipped with a comprehensive suite of 
                        IT solutions, we stand ready to fulfill all your hardware and software requirements.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div appIntersectionObserver class="service-div">
        <div class="container mb-5">
            <div class="row mb-5">
                <div appIntersectionObserver class="hidden-from-left col-md-6 col-sm-12 parent p-5">
                    <img loading="lazy" src="assets/strategy-2.webp" width="423" height="238" class="d-block w-75 h-auto image1 rounded shadow" alt="...">
                    <img loading="lazy" src="assets/strategy-1.webp" width="330" height="220" class="d-block w-50 h-auto image2 rounded shadow" alt="...">
                </div>
                <div appIntersectionObserver class="hidden-from-right col-md-6 col-sm-12 serviceColRight">
                    <div class="serviceRight mb-4 ps-3">
                        <h1 class="serviceNumber theme-heading">02</h1>
                        <h2 class="theme-heading">WE SPECIALIZE IN DELIVERING CUSTOMIZED STRATEGY AND 
                            DESIGN SOLUTIONS METICULOUSLY TAILORED TO MEET YOUR DISTINCT AND INDIVIDUAL NEEDS
                        </h2>
                    </div>
                    <button class="theme-button home-button" [routerLink]="['/services']"><span>Our
                            Services</span></button>
                </div>
            </div>
            <div class="row">
                <div appIntersectionObserver class="hidden-from-left col-md-6 col-sm-12">
                    <h3 class="theme-heading">Go 24/7</h3>
                    <p class="theme-p">Imagine the possibility of breaking away from conventional business 
                        hours and expanding your service availability around the clock. Start offering your services 
                        precisely when your clients need them. We have the capability to turn that vision into reality for you.</p>
                </div>
                <div appIntersectionObserver class="hidden-from-right col-md-6 col-sm-12">
                    <h3 class="theme-heading">Simplicity</h3>
                    <p class="theme-p">Complicated solutions lead to frustrated users, and frustrated users result in lower revenue streams. 
                        Our solutions are optimized and user-friendly, ensuring a seamless and positive user experience
                    </p>
                </div>
            </div>
            <div class="row">
                <div appIntersectionObserver class="hidden-from-left col-md-6 col-sm-12">
                    <h3 class="theme-heading">Competitive Prices</h3>
                    <p class="theme-p">Reduce your overhead expenses by partnering with us. 
                        Redirect those savings towards attracting more customers and growing your business.
                    </p>
                </div>
                <div appIntersectionObserver class="hidden-from-right col-md-6 col-sm-12">
                    <h3 class="theme-heading">Innovate</h3>
                    <p class="theme-p">
                        Embrace the new as we usher out the old. Our clients continually upgrade and grow with us for a reason.
                         In a constantly evolving technological landscape, we aim to take you on this transformative journey with us.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="service-div">
        <div class="container mb-5">
            <div class="row">
                <div appIntersectionObserver class="hidden-from-left col-md-5 col-sm-12">
                    <div class="col serviceColLeft">
                        <div class="col serviceLeft pe-3">
                            <h1 class="serviceNumber theme-heading">03</h1>
                            <h2 class="theme-heading">
                                WE TAKE PRIDE IN OFFERING OUR CUSTOMERS UNWAVERING PROCESS SUPPORT AND WORLD-CLASS TECHNICAL ASSISTANCE
                            </h2>
                        </div>
                    </div>
                    <p class="theme-p">
                        We offer process and support services, recognizing the unique and diverse nature of each business and project. 
                        This understanding enables us to develop strategies for systematically analysing, planning, implementing, 
                        and maintaining projects in well-defined phases.
                    </p>
                    <p class="theme-p">
                        Our Process Framework involves.
                    </p>
                </div>
                <div class="col-md-7 col-sm-12">
                    <div class="row justify-content-center">
                        <div appIntersectionObserver
                            class="hidden-from-right service3-child col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10  rounded shadow m-3 p-4 service3-card">
                            <h3 class="theme-heading">RESEARCH ANALYSIS</h3>
                            <p class="theme-p">Our team conducts a thorough analysis of your organization's current procedures and 
                                workflow to gain a comprehensive understanding of your processes and requirements.</p>
                        </div>
                        <div appIntersectionObserver
                            class="hidden-from-right service3-child col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 rounded shadow m-3 p-4 service3-card">
                            <h3 class="theme-heading">MEETING & SCOPING</h3>
                            <p class="theme-p">We schedule a meeting to sit down with you, listening to your company's story and understanding your challenges. 
                                During this collaborative session, we establish mutually agreed-upon goals and timelines for the project.
                            </p>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div appIntersectionObserver
                            class="hidden-from-right service3-child col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 rounded shadow m-3 p-4 service3-card">
                            <h3 class="theme-heading">IMPLEMENTATION</h3>
                            <p class="theme-p">We formulate the initiation of finalized strategies to be implemented, 
                                ensuring minimal to zero disruption to current business operations.
                            </p>
                        </div>
                        <div appIntersectionObserver
                            class="hidden-from-right service3-child col-xl-5 col-lg-5 col-md-5 col-sm-10 col-10 rounded shadow m-3 p-4 service3-card">
                            <h3 class="theme-heading">MAINTAIN</h3>
                            <p class="theme-p">We establish multiple touchpoints to monitor the implemented processes, 
                                ensuring alignment with the set goals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row service3Row2">
            <div class="col p-5">
                <h2 class="text-white theme-heading">We support a wide variety of products and services from renowned brands 
                    because we believe that different businesses require unique solutions
                </h2>
                <p class="text-white theme-p">Because we believe that different businesses require different solutions.
                </p>
            </div>
            <div class="col desktop-only service3Row2ServiceCol">
                <div class="my-5">
                    <div class="row justify-content-center my-3">
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-1.webp" alt="..."  height="70" width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-2.webp" alt="..."  height="70" width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-3.webp" alt="..."  height="70" width="116"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-4.webp" alt="..."   width="117"></div>
                    </div>
                    <div class="row justify-content-center my-3">
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-5.webp" alt="..."  height="70" width="116"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-6.webp" alt="..."  height="70" width="116"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-7.webp" alt="..."  height="70" width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-8.webp" alt="..."   width="117"></div>
                    </div>
                    <div class="row justify-content-center my-3">
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-9.webp" alt="..."  height="70" width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-10.webp" alt="..."  height="70" width="116"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-11.webp" alt="..."  height="70" width="116"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-12.webp" alt="..."   width="117"></div>
                    </div>
                    <div class="row justify-content-center my-3">
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-17.webp" alt="..."  width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-14.webp" alt="..."  width="116" ></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-15.webp" alt="..."  height="70" ></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-16.webp" alt="..."   width="117"></div>
                    </div>
                    <div class="row justify-content-center my-3">
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-13.webp" alt="..."   height="70"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-18.webp" alt="..."   width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-19.webp" alt="..."   width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-20.webp" alt="..."   width="117"></div>
                    </div>
                    <div class="row justify-content-center my-3">
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-21.webp" alt="..."    width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-22.webp" alt="..."   width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-23.webp" alt="..."   width="117"></div>
                        <div class="col d-flex align-items-center justify-content-center desktop-only-images"><img loading="lazy" src="assets/support-24.webp" alt="..."   width="117"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-only mb-5 p-5">
            <div class="row ">
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-5.webp" alt="..."  height="70"  width="117">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-6.webp" alt="..."  height="70"  width="117">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-7.webp" alt="..."  height="70"  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-8.webp" alt="..."  height="70"  width="117">
                </div>
            </div>
            <div class="row">
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-1.webp" alt="..."  height="70"  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-2.webp" alt="..."  height="70"  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-3.webp" alt="..."  height="70"  width="117">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-4.webp" alt="..."  height="70"  width="116">
                </div>
            </div>
            <div class="row">
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-9.webp" alt="..."  height="70"  width="117">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-10.webp" alt="..."  height="70"  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-11.webp" alt="..."  height="70"  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-12.webp" alt="..."  height="70"  width="117">
                </div>
            </div>
            <div class="row">
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-13.webp" alt="..."  height="70">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-14.webp" alt="..."  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-15.webp" alt="..."  height="70">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-16.webp" alt="..."  width="117">
                </div>
            </div>
            <div class="row">
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-17.webp" alt="..."  width="117">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-18.webp" alt="..."  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-19.webp" alt="..."  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-20.webp" alt="..."  width="117">
                </div>
            </div>
            <div class="row">
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-21.webp" alt="..."  width="117">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-22.webp" alt="..."   width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-23.webp" alt="..."  width="116">
                </div>
                <div class="col d-flex align-items-center justify-content-center support-mobile d-block"><img loading="lazy" src="assets/support-24.webp" alt="..."  width="117">
                </div>
            </div>
        </div>
    </div>
    <div appIntersectionObserver class="hidden service-div p-2">
        <div class="container mb-5">
            <div class="row text-center mb-5 service-row-center">
                <h1 class="serviceNumber theme-heading">04</h1>
                <h2 class="mb-5 theme-heading">WE MAINTAIN STRONG RELATIONSHIPS BOTH INTERNALLY AND EXTERNALLY</h2>
                <h3 class="mb-5 theme-heading">"Setting us above the rest is our commitment to maintaining customer 
                    satisfaction at the forefront of our priorities."
                </h3>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-12 mb-4 d-flex justify-content-center">
                    <img loading="lazy" src="assets/fist-bump.webp" height="477"  width="636" class="d-block w-75 h-auto rounded shadow" alt="...">
                </div>
                <div class="col-md-6 col-sm-12">
                    <span>
                        <h3 class="theme-heading">Client Relationship Building</h3>
                        <p class="theme-p">Establishing and nurturing strong relationships with clients to understand their needs and expectations.</p>
                    </span>
                    <span>
                        <h3 class="theme-heading">Account Planning</h3>
                        <p class="theme-p">Developing strategic plans for each client account, 
                            aligning with their business goals and objectives.</p>
                    </span>
                    <span>
                        <h3 class="theme-heading">Communication Channels</h3>
                        <p class="theme-p">Ensuring clear and effective communication channels between the client and our team.
                        </p>
                    </span>
                    <span>
                        <h3 class="theme-heading">Client Satisfaction</h3>
                        <p class="theme-p">Monitoring and ensuring high levels of client satisfaction through regular feedback and assessment.
                        </p>
                    </span>
                </div>
            </div>
        </div>
        <div class="row service4Row2 mb-2">
            <div class="col w-25">
                <h2 class="text-white theme-heading">Embark on an exciting career with us to learn and grow
                    <br />alongside the best
                    in the
                    industry
                </h2>
                <p class="text-white theme-p">Start your career where you will get every opportunity to grow and improve
                    your
                    skills.
                </p>
                <span><button class="button-white home-button" [routerLink]="['/careers']"><span>Our
                            Careers</span></button></span>
            </div>
        </div>
    </div>
    <div appIntersectionObserver class="hidden service-div p-2">
        <div class="container mb-5">
            <div class="row text-center mb-5 service-row-center">
                <h1 class="serviceNumber theme-heading">05</h1>
                <h2 class="theme-heading">OUR PORTFOLIO</h2>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <p class="theme-p">GO2 Solutions (GO2) provides an extensive array of Managed Services, empowering organizations 
                        to outsource ICT functions and concentrate on core deliverables, ultimately enhancing organizational profitability. 
                        Our suite of Managed Services is designed to streamline operations, optimize IT infrastructure, and ensure a robust 
                        and secure technological environment. By entrusting ICT functions to GO2, businesses can benefit from specialized expertise, 
                        proactive support, and cost-effective solutions, allowing them to thrive in their respective industries.
                    </p>        
                    <button class="theme-button home-button home-button mb-2" [routerLink]="['/portfolio']"><span>Portfolio
                    </span></button>          
                    <p class="theme-p">
                        The below testimonial from our clients speaks volume of our commitment to customer satisfaction and excellence in service delivery:
                    </p>                   
                </div>
            </div>
            <div class="row bubble-row">
                <div class="col">                  
                    <div class="bubble-container">
                        <div class="bubble" style="border-left: 10px solid rgb(255, 204, 20);">
                            <p class="theme-p">GO2 Solutions has been instrumental in transforming our IT landscape. Their commitment to understanding our unique needs 
                                and delivering tailored solutions has significantly improved our operational efficiency. Their team's responsiveness 
                                and expertise make them a valued partner.</p>
                            <div class="detail-left"><i class="fa fa-user-circle-o" aria-hidden="true"></i> Shop & Save</div>
                        </div>
                        <div class="bubble" style="border-right: 10px solid orange;">
                            <p class="theme-p">I can highly recommend GO2 Solutions for any web based needs your company may have. We at Heritage Fiji are also extremely pleased to 
                                have an ongoing relationship with GO2 Solutions, due to the quality of their services we have experienced to date.</p>
                            <div class="detail-right"><i class="fa fa-user-circle-o" aria-hidden="true"></i> Heritage Fiji</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>