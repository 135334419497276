import { Component } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  readonly carousal1 = "../../../../../assets/carousel-1.webp"
  readonly carousal2= "../../../../../assets/carousel-2.webp"
  readonly carousal3= "../../../../../assets/carousel-3.webp"
}
