<nav class="navbar navbar-expand-lg navbar-container fixed-top nav-border" [style.backgroundColor]="navbarBackground">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/']">
            <img [ngSrc]="logo" alt="Logo" width="124" height="60" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars text-white"></i>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <li class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/']">Home</a>
                </li>
                <li class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/about']">About</a>
                </li>
                <li class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/services']">Services</a>
                </li>
                <li class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: false }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/portfolio']">Portfolio</a>
                </li>
                <li *ngIf="showPromotions" class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/promotions']">Promotions</a>
                </li>
                <li class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: false }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/careers']">Careers</a>
                </li>
                <li class="nav-item theme-li" [routerLinkActive]="'tab-active'" [routerLinkActiveOptions]="{ exact: true }">
                    <a class="nav-link text-white fw-bold" [routerLink]="['/contact-us']">Contact Us</a>
                </li>
            </ul>
        </div>
    </div>
</nav>