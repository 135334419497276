import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';

  isRouteAdmin: boolean;

  constructor(private titleService: Title, private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const routeTitle = this.getTitleFromRoute(this.route);

        this.titleService.setTitle(routeTitle);
      }
    });
  }

  ngOnInit() {
    let self = this;
    const currentRoute = this.router.url;
    self.isRouteAdmin = currentRoute.includes('/admin');
  }

  private getTitleFromRoute(route: ActivatedRoute): string {
    let title = ''; 
    while (route.firstChild) {
      route = route.firstChild;
      if (route.snapshot.data && route.snapshot.data.title) {
        title = route.snapshot.data.title + ' | ';
      }
    }
    return title + 'GO2 Solutions';
  }
  
}
