import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  readonly logo = "assets/logo.webp"

  @Input() showPromotions: boolean
  navbarBackground = '#333333'; 

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const scrollY = window.scrollY;

    if (scrollY > 100) {
      this.navbarBackground = 'rgba(0, 0, 0, 0.8)'; 
    } else {
      this.navbarBackground = '#333333'; 
    }
  }
}
