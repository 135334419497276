import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root',
})
export class AuthorizeService {
  private loggedIn = false;

  constructor(private router: Router) {}

  isAuthenticated(): boolean {
    let self = this;
    let token = localStorage.getItem('token');
    if (token !== null) {
      self.loggedIn = true;
    } else {
      self.loggedIn = false;
    }
    return self.loggedIn;
  }

  getToken(): string { 
    return  localStorage.getItem('token');
  }

  decodeToken(): string {
    let token = localStorage.getItem('token');
    return jwt_decode(token);
  }

  getEmail():string{
    let decodeToken = this.decodeToken()
    return decodeToken['email']
  }

  getRoles(): string[]{
    let decodeToken = this.decodeToken()
    return decodeToken['roles']
  }

  logout():void { 
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

  setToken(token): void { 
    return  localStorage.setItem('token', token);
  }
}
