import { Component, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top-button',
  templateUrl: './scroll-to-top-button.component.html',
  styleUrls: ['./scroll-to-top-button.component.css']
})
export class ScrollToTopButtonComponent {
  isShow: boolean = false;
  scrollPercentage: number = 0;
  degrees: number = 0;

  topPosToStartShowing = 100;

  constructor(private renderer: Renderer2, private el: ElementRef) {}
  
  @HostListener('window:scroll')
  onWindowScroll() {
    const scrollOffset =
      window.scrollY ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    const windowHeight =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    this.scrollPercentage = (scrollOffset / windowHeight) * 100;
    this.degrees = Math.round(this.scrollPercentage/100 * 360);

    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.isShow = scrollPosition >= this.topPosToStartShowing;

     // Set background dynamically
     const bgElement = this.el.nativeElement.querySelector('.bg');
     this.renderer.setStyle(bgElement, 'background', `conic-gradient(orange ${this.degrees}deg, #ddd ${this.degrees}deg)`);
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}